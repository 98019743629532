import { useQuery } from 'react-query'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse } from 'BackendApi'
import { getRandomExampleHeader } from '@app/api/utils/mock'
import {
  ApiGetIntegrationListenerResponse,
  ApiIntegrationListener,
} from '@app/@typings/Api/integration'

const fetchIntegrationListener = async (
  integrationId: number | string
): Promise<ApiIntegrationListener | undefined> => {
  const apiResponse =
    await apiAuthClient.get<ApiGetIntegrationListenerResponse>(
      endpoints.getIntegrationListener({ integrationId }),
      {
        headers: {
          ...getRandomExampleHeader([
            'example-1',
            // 'status-new',
          ]),
        },
      }
    )
  return apiResponse.data.data
}

export const useGetIntegrationListener = (integrationId?: number | string) =>
  useQuery<ApiIntegrationListener | undefined, ApiErrorResponse>(
    ['integration-listener', integrationId],
    () => fetchIntegrationListener(integrationId!),
    {
      enabled: !!integrationId,
    }
  )
