import styled from 'styled-components'

export const Header = styled.header`
  width: 100%;
  min-height: 35px;
  padding: 0.5rem 2rem;
  margin-bottom: 2rem;
  background-color: #3fb1f3;
  display: flex;
  align-items: center;
`

export const Body = styled.div`
  padding: 0 1rem;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;

  a {
    align-self: flex-end;
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
`

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.9rem;

  h2 {
    margin-top: 1.5rem;
    font-size: 1.3rem;
  }
`

export const Paragraph = styled.p`
  font-size: 0.8rem;
  color: #363636;
`

export const ButtonWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 3.1rem;
  width: 60%;
`
