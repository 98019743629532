import React, { useEffect } from 'react'
import { IncomingDataInitialStep } from '@features/nbee/IncomingDataContent/IncomingDataInitialStep'
import { useHistory, useParams } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import { IntegrationCredentialEditParams } from 'LeadsBridgeApp'
import { useGetIntegrationListener } from '@app/api/getIntegrationListener'
import { Loader } from '@app/components/Basic/Loader'
import {
  setIntegrationListenerAction,
  setIntegrationListenerStatus,
} from '@app/store/actions/IntegrationListenerActions'
import { useAppDispatch } from '@app/store/hooks'
import { parseApiError } from '@app/api/utils/error'
import { sendAlertMessage } from '@app/store/actions/ApplicationConfigurationActions'
import { useUpdateIntegrationListener } from '@app/api/updateIntegrationListener'
import { useGetAppById } from '@app/api/getAppById'
import { getListenerAppName } from '@features/nbee/IncomingDataContent/utils'
import { useRetrieveAppCredentials } from '@app/api/postRetrieveAppCredentials'
import {
  makeIntegrationFormInitialValues,
  transformIntegrationFormValuesToApiSaveRequestBody,
} from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/utils'
import { IntegrationFormValues } from 'Nbee'
import { ApiIntegration } from 'BackendApi'
import { useUpdateIntegration } from '@app/api/updateIntegration'
import { FormikHelpers } from 'formik'
import { SubmitAction } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm'
import { useGetIntegrationById } from '@app/api/getIntegrationById'

export const IncomingDataScreen: React.FC = () => {
  const dispatch = useAppDispatch()

  const { appId, integrationId } = useParams<IntegrationCredentialEditParams>()
  const appIdNumber = parseInt(appId, 10)

  const history = useHistory()

  const {
    data: appAuthSchemaResponse,
    mutate: retrieveAuthSchema,
    isLoading: isLoadingAppAuthSchema,
    error: errorApiAppAuthSchema,
  } = useRetrieveAppCredentials()

  // retrieving auth schema on mount
  useEffect(() => {
    retrieveAuthSchema({
      appId: appIdNumber,
      settings: [],
    })
  }, [appIdNumber])

  const {
    data: existingIntegration,
    isLoading: isLoadingExistingIntegration,
    error: errorApiGetIntegration,
  } = useGetIntegrationById(integrationId)

  const {
    data: integrationListenerData,
    isLoading: isGettingIntegrationListener,
    error: errorGettingIntegrationListener,
  } = useGetIntegrationListener(integrationId)

  const {
    data: updatedIntegrationData,
    mutate: updateIntegrationListener,
    isLoading: isUpdatingIntegrationListener,
    error: errorUpdatingIntegrationListener,
  } = useUpdateIntegrationListener()

  const {
    mutate: updateIntegration,
    isLoading: isUpdatingIntegration,
    error: integrationUpdateApiError,
    data: updatedIntegrationCredentials,
  } = useUpdateIntegration()

  const {
    data: appData,
    isLoading: isLoadingApp,
    error: errorApiGetApp,
  } = useGetAppById(appId)

  const isLoading =
    isGettingIntegrationListener ||
    isUpdatingIntegrationListener ||
    isUpdatingIntegration ||
    isLoadingAppAuthSchema ||
    isLoadingExistingIntegration ||
    isLoadingApp

  const isError =
    errorGettingIntegrationListener ||
    errorUpdatingIntegrationListener ||
    errorApiGetApp ||
    integrationUpdateApiError ||
    errorApiAppAuthSchema

  // data just saved
  const optimisticIntegrationData =
    (updatedIntegrationData as unknown as ApiIntegration) ||
    updatedIntegrationCredentials

  // we build the full auth schema by merging existing data into the full app auth schema
  const appAuthSchema = appAuthSchemaResponse?.data?.credentials.fields || []
  const initialValues = appData
    ? makeIntegrationFormInitialValues(
        appAuthSchema,
        appData.name,
        optimisticIntegrationData || existingIntegration
      )
    : null

  const handleContinue = (
    formValues?: IntegrationFormValues,
    formikHelpers?: FormikHelpers<IntegrationFormValues>,
    action?: SubmitAction
  ) => {
    formikHelpers?.setSubmitting(true)

    // If we already have fields, we can move to the table step
    if (
      integrationListenerData?.fields &&
      integrationListenerData.fields.length > 0
    ) {
      history.push(
        appRoutes.incomingDataScreenFields.makeUrl({
          appId,
          integrationId,
        })
      )
    } else if (
      initialValues &&
      formValues &&
      (formValues?.credentials.length || 0) > 0 &&
      action === 'update'
    ) {
      // otherwise, if we also have credentials, we first update the integration credentials
      updateIntegration(
        {
          integrationId: integrationId,
          requestBody: transformIntegrationFormValuesToApiSaveRequestBody(
            formValues,
            appIdNumber
          ),
        },
        {
          onSuccess: () => {
            // now we can update the integration listener status
            updateIntegrationListener(
              {
                requestBody: {
                  status: 1,
                },
                integrationId: `${integrationId}`,
              },
              {
                onSuccess: () => {
                  // finally, we move to the next step
                  dispatch(setIntegrationListenerStatus(1))
                  history.push(
                    appRoutes.incomingDataScreenFields.makeUrl({
                      appId,
                      integrationId,
                    })
                  )
                },
              }
            )
          },
        }
      )
    } else {
      // if we don't have credentials, we just update the listener
      updateIntegrationListener(
        {
          requestBody: {
            status: 1,
          },
          integrationId: `${integrationId}`,
        },
        {
          onSuccess: () => {
            // finally, we move to the next step
            dispatch(setIntegrationListenerStatus(1))
            history.push(
              appRoutes.incomingDataScreenFields.makeUrl({
                appId,
                integrationId,
              })
            )
          },
        }
      )
    }
  }

  // dispatch integration listener data to redux store
  useEffect(() => {
    if (integrationListenerData) {
      dispatch(setIntegrationListenerAction({ data: integrationListenerData }))
    }
  }, [integrationListenerData, dispatch])

  // In case of error when getting the integration listener or credentials, or updating them, we show an error message
  useEffect(() => {
    if (isError) {
      const error = isError
      const errorMessage = parseApiError(error!)

      dispatch(
        sendAlertMessage({
          isDismissable: false,
          message: errorMessage.message,
          useTranslation: true,
        })
      )
    }
  }, [isError])

  return (
    <>
      {isLoading ||
      errorGettingIntegrationListener ||
      isLoadingAppAuthSchema ? (
        <Loader $active={isLoading} $dimmer />
      ) : null}
      {appData &&
      initialValues &&
      integrationListenerData &&
      existingIntegration &&
      appAuthSchemaResponse ? (
        <>
          <IncomingDataInitialStep
            initialValues={initialValues}
            appLogoUri={
              appData?.logoUriSmall ||
              'https://leadsbridge.com/wp-content/themes/leadsbridge/img/integration-lg-logos/logo168.png'
            }
            appId={Number(appId)}
            appName={getListenerAppName(appData)}
            onContinueRequest={handleContinue}
            isLoading={isLoading}
          />
        </>
      ) : null}
    </>
  )
}
