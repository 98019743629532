import React, { useEffect, useState } from 'react'
import { IncomingDataTableStep } from '@features/nbee/IncomingDataContent/IncomingDataTableStep'
import { useHistory, useParams } from 'react-router-dom'
import { IncomingDataParams } from 'LeadsBridgeApp'
import { useAppDispatch, useAppSelector } from '@app/store/hooks'
import { useUpdateIntegrationListener } from '@app/api/updateIntegrationListener'
import { ApiIntegrationListenerField } from '@app/@typings/Api/integration'
import {
  setIntegrationListenerFields,
  setIntegrationListenerStatus,
} from '@app/store/actions/IntegrationListenerActions'
import { appRoutes } from '@app/routes'
import { usePusherChannel } from '@app/hooks/usePusherChannel'
import { Loader } from '@app/components/Basic/Loader'
import { parseApiError } from '@app/api/utils/error'
import { sendAlertMessage } from '@app/store/actions/ApplicationConfigurationActions'
import { useGetIntegrationUsage } from '@app/api/getIntegrationUsage'
import { useGetAppById } from '@app/api/getAppById'
import { getListenerAppName } from '@features/nbee/IncomingDataContent/utils'
import { useGetIntegrationFields } from '@app/api/getIntegrationListenerFields'

export const IncomingDataScreenFields: React.FC = () => {
  const { appId, integrationId } = useParams<IncomingDataParams>()
  const dispatch = useAppDispatch()
  const history = useHistory()

  // when isRecognizingFields is true, the loader is shown and the channel is subscribed
  const [isRecognizingFields, setIsRecognizingFields] = useState<boolean>(true)

  const [isConfirmingReload, setIsConfirmingReload] = useState(0)

  const integrationListener = useAppSelector(
    (state) => state.integrationListener
  )

  const { data: integrationUsage, isLoading: isGettingIntegrationUsage } =
    useGetIntegrationUsage(integrationId)

  const {
    data: integrationFields,
    isLoading: isLoadingIntegrationFields,
    refetch: refetchIntegrationFields,
  } = useGetIntegrationFields(integrationId)

  const {
    data: appData,
    isLoading: isLoadingApp,
    error: errorApiGetApp,
  } = useGetAppById(appId)

  const activeBridges = integrationUsage?.data.activeBridges ?? 0
  const totalBridges = integrationUsage?.data.totalBridges ?? 0

  const fields = integrationListener.data
    ?.fields as ApiIntegrationListenerField[]

  useEffect(() => {
    if (integrationFields) {
      dispatch(setIntegrationListenerFields(integrationFields))
    }
  }, [integrationFields])

  const {
    mutate: updateIntegrationListener,
    isLoading: isUpdatingIntegrationListener,
    error: errorUpdatingIntegrationListener,
  } = useUpdateIntegrationListener()

  useEffect(() => {
    if (history.action === 'POP') {
      history.push(
        appRoutes.incomingDataScreen.makeUrl({ appId, integrationId })
      )
    }
  }, [])

  const handleReload = () => {
    if (isConfirmingReload < 1 && totalBridges && activeBridges) {
      setIsConfirmingReload((prevState) => prevState + 1)
    } else {
      updateIntegrationListener(
        {
          requestBody: { status: 1 },
          integrationId: `${integrationId}`,
        },
        {
          onSuccess: () => {
            dispatch(setIntegrationListenerStatus(1))
            setIsConfirmingReload(0)
            setIsRecognizingFields(true)
          },
        }
      )
    }
  }

  const handleContinue = () => {
    updateIntegrationListener(
      {
        requestBody:
          fields?.length > 0 ? { status: 2, fields: fields } : { status: 3 },
        integrationId: `${integrationId}`,
      },
      {
        onSuccess: () => {
          history.push(
            appRoutes.incomingDataScreenSave.makeUrl({ appId, integrationId })
          )
        },
      }
    )
  }

  // Subscribe to the channel only when the integrationListener is new and when the status is loading
  usePusherChannel(
    integrationListener.data?.channel || 'test-channel',
    'webhookdata.parsed',
    integrationListener.data?.status === 1 && isRecognizingFields,
    () =>
      updateIntegrationListener(
        {
          integrationId: `${integrationId}`,
          requestBody: fields?.length > 0 ? { status: 2 } : { status: 3 },
        },
        {
          onSuccess: () => {
            dispatch(setIntegrationListenerStatus(fields?.length > 0 ? 2 : 3))
          },
        }
      ),
    () => {
      refetchIntegrationFields()
    }
  )

  // When the fields are recognized, the loader is hidden
  useEffect(() => {
    if (Array.isArray(fields) && fields.length > 0) {
      setIsRecognizingFields(false)
    }
  }, [fields])

  // In case of error when updating the integration listener
  useEffect(() => {
    if (errorUpdatingIntegrationListener) {
      setIsRecognizingFields(false)
      const error = errorUpdatingIntegrationListener
      const errorMessage = parseApiError(error!)

      dispatch(
        sendAlertMessage({
          isDismissable: true,
          message: errorMessage.message,
          useTranslation: true,
        })
      )
    }
  }, [errorUpdatingIntegrationListener])

  const isLoading =
    isUpdatingIntegrationListener || isGettingIntegrationUsage || isLoadingApp

  return (
    <>
      {isLoading ? <Loader $active $dimmer /> : null}
      <IncomingDataTableStep
        appLogoUri={
          appData?.logoUriSmall ||
          'https://leadsbridge.com/wp-content/themes/leadsbridge/img/integration-lg-logos/logo168.png'
        }
        onReload={handleReload}
        onContinue={handleContinue}
        isRecognizingFields={isRecognizingFields}
        fields={fields || []}
        isConfirmingReload={isConfirmingReload}
        activeBridges={activeBridges}
        totalBridges={totalBridges}
        resetConfirmingReload={setIsConfirmingReload}
        appName={getListenerAppName(appData)}
        listenerType={integrationListener.data?.type || 'url'}
        appId={appId}
        integrationId={integrationId ?? ''}
      />
    </>
  )
}
