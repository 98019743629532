import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse, ApiGetIntegrationResponse } from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { Integration } from 'Nbee'
import { getRandomExampleHeader } from '@app/api/utils/mock'

const fetchIntegrationById = async (
  integrationId: number | string
): Promise<ApiGetIntegrationResponse> => {
  const apiResponse = await apiAuthClient.get(
    endpoints.getIntegrationById({ integrationId }),
    {
      headers: {
        ...getRandomExampleHeader(['example-1']),
      },
    }
  )
  return apiResponse.data
}

const adaptApiResponse = (
  apiResponse: ApiGetIntegrationResponse
): Integration | undefined => {
  return apiResponse?.data?.integration
}

export const useGetIntegrationById = (integrationId?: number | string) =>
  useQuery<
    ApiGetIntegrationResponse,
    ApiErrorResponse,
    Integration | undefined
  >(
    ['integration', integrationId],
    () => fetchIntegrationById(integrationId!),
    {
      staleTime: 1000,
      enabled: !!integrationId,
      select: adaptApiResponse,
    }
  )
