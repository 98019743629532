import { useQuery } from 'react-query'
import { endpoints } from '@app/api/config'
import { ApiGetAppsResponse, ApiErrorResponse } from 'BackendApi'
import { adaptApiAppReponse } from '@app/api/utils/apps'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { App } from 'Nbee'

const fetchAvailableApps = async (): Promise<ApiGetAppsResponse> => {
  const apiResponse = await apiAuthClient.get(endpoints.getApps, {
    headers: {
      Prefer: 'example=example-1',
    },
  })
  return apiResponse.data
}

export const useFetchAllApps = () =>
  useQuery<ApiGetAppsResponse, ApiErrorResponse, App[]>(
    ['allApps'],
    () => fetchAvailableApps(),
    {
      select: adaptApiAppReponse,
    }
  )
