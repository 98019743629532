import { useMutation } from 'react-query'
import { endpoints } from '@app/api/config'
import {
  ApiErrorResponse,
  ApiGetIntegrationResponse,
  ApiSaveIntegrationRequestBody,
} from 'BackendApi'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { AxiosResponse } from 'axios'
import { getRandomExampleHeader } from './utils/mock'

type PostNewIntegrationParams = {
  requestBody: ApiSaveIntegrationRequestBody
}

const postNewIntegration = async ({
  requestBody,
}: PostNewIntegrationParams): Promise<ApiGetIntegrationResponse> => {
  const apiResponse: AxiosResponse<ApiGetIntegrationResponse> =
    await apiAuthClient.post(endpoints.saveIntegration, requestBody, {
      headers: {
        ...getRandomExampleHeader(['example-1']),
      },
    })

  return apiResponse.data
}

export const usePostNewIntegration = () =>
  useMutation<
    ApiGetIntegrationResponse,
    ApiErrorResponse,
    PostNewIntegrationParams
  >(postNewIntegration)
