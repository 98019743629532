import React from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import { InputAutoSelectCopy } from '@components/InputAutoSelectCopy'
import LbLogoSvg from '@assets/images/logo-lb-small.svg'
import { useTranslation } from 'react-i18next'
import {
  Wrapper,
  ButtonWrapper,
  Header,
  Title,
  Paragraph,
  Body,
} from './styled'
import { useAppSelector } from '@app/store/hooks'
import { IntegrationFormValues } from 'Nbee'
import { Formik, Form, FormikHelpers } from 'formik'
import { FormInner } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/FormInner'
import { SubmitAction } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm'
import { makeAuthCredentialValidationSchema } from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm/validation'

interface Props {
  appLogoUri: string
  onContinueRequest: (
    formValues: IntegrationFormValues,
    formikHelpers: FormikHelpers<IntegrationFormValues>,
    action: SubmitAction
  ) => void
  appId: number
  appName: string
  initialValues: IntegrationFormValues
  isLoading: boolean
}

export const IncomingDataInitialStep: React.FC<Props> = ({
  appLogoUri,
  isLoading,
  onContinueRequest,
  appName,
  appId,
  initialValues,
}) => {
  const { t } = useTranslation()
  const integrationListener = useAppSelector(
    (state) => state.integrationListener
  )

  const showedName = appName
  const listenerType = integrationListener.data?.type ?? 'url'
  return (
    <Wrapper>
      <Header>
        <img src={LbLogoSvg} width='40' alt='Logo description' />
      </Header>
      <Body>
        <Title>
          <img src={appLogoUri} width='45' alt='App logo' />
          <h2>
            {t(`incomingDataPopup.${listenerType}.titleFirstStepWebhook`, {
              appName: showedName,
            })}
          </h2>
        </Title>
        <Paragraph>
          {t(`incomingDataPopup.${listenerType}.paragraphFirstStepWebhook`, {
            appName: showedName,
          })}
        </Paragraph>
        {integrationListener.data ? (
          <InputAutoSelectCopy
            // docUrl={'https://leadsbridge.com'}
            linkKey={t('incomingDataPopup.linkTextFirstStepWebhook')}
            label={t(`incomingDataPopup.${listenerType}.inputLabel`)}
            inputValue={integrationListener.data?.slug}
            tooltip={t(
              `incomingDataPopup.${listenerType}.inputCopyTooltipWebhook`,
              {
                appName: showedName,
              }
            )}
          />
        ) : null}
        <Formik
          initialValues={initialValues}
          validationSchema={() => makeAuthCredentialValidationSchema(false)}
          onSubmit={(values, formikHelpers) => {
            onContinueRequest(values, formikHelpers, 'update')
          }}
        >
          {() => {
            return (
              <div
                style={{
                  width: '100%',
                }}
              >
                <Form>
                  <FormInner
                    appId={appId}
                    appName={appName}
                    showName={false}
                    handleErrorsUpdate={() => {
                      console.log('error')
                    }}
                  />

                  <ButtonWrapper>
                    <Button
                      style={{ width: '100%' }}
                      $variant={'primary'}
                      type={'submit'}
                      disabled={isLoading}
                    >
                      {t('incomingDataPopup.ctaRecognizedWebhook')}
                    </Button>
                  </ButtonWrapper>
                </Form>
              </div>
            )
          }}
        </Formik>
      </Body>
    </Wrapper>
  )
}
