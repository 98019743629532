import { useQuery } from 'react-query'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { endpoints } from '@app/api/config'
import { ApiErrorResponse } from 'BackendApi'
import { getRandomExampleHeader } from '@app/api/utils/mock'
import {
  ApiIntegrationFields,
  ApiIntegrationFieldsResponse,
} from '@app/@typings/Api/integration'

const fetchIntegrationFields = async (
  integrationId: number | string
): Promise<ApiIntegrationFieldsResponse | undefined> => {
  const apiResponse = await apiAuthClient.get<ApiIntegrationFieldsResponse>(
    endpoints.getIntegrationFields({ integrationId }),
    {
      headers: {
        ...getRandomExampleHeader(['example-1']),
      },
    }
  )
  return apiResponse.data
}

export const useGetIntegrationFields = (integrationId?: number | string) =>
  useQuery<ApiIntegrationFieldsResponse | undefined, ApiErrorResponse>(
    ['integration-fields-listener', integrationId],
    () => fetchIntegrationFields(integrationId!),
    {
      enabled: false,
    }
  )
